<template>
  <div ref="wrapper" :class="['w-full h-full', $props.class]">
    <div ref="root">
      <div
        ref="sphere1"
        class="rounded-full blur-[100px]"
        :style="{
          backgroundColor: colors[0],
          width: sphereSize,
          height: sphereSize,
        }"
      ></div>
      <div
        ref="sphere2"
        class="rounded-full blur-[100px]"
        :style="{
          backgroundColor: colors[1],
          width: sphereSize,
          height: sphereSize,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementBounding } from "@vueuse/core";
import { Group, PerspectiveCamera, Scene } from "three";
import {
  CSS3DRenderer,
  CSS3DObject,
} from "three/examples/jsm/renderers/CSS3DRenderer";
import { onMounted, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    class?: string;
    colors?: [string, string];

    sphereSize?: string;
    sphereOffset?: number;
  }>(),
  {
    colors: () => ["#A637F54B", "#EA11A04B"],
    class: "",
    sphereSize: "124px",
    sphereOffset: 100,
  }
);

const wrapper = ref(null as unknown as HTMLDivElement);
const root = ref(null as unknown as HTMLDivElement);
const sphere1 = ref(null as unknown as HTMLDivElement);
const sphere2 = ref(null as unknown as HTMLDivElement);

const bounding = useElementBounding(wrapper);
const renderer = ref<CSS3DRenderer | null>(null);

watch(
  [bounding.width, bounding.height, renderer],
  () => {
    if (renderer.value) {
      renderer.value.setSize(bounding.width.value, bounding.height.value);
    }
  },
  { immediate: true }
);

function init() {
  const camera = new PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    1,
    5000
  );
  camera.position.z = 400;
  camera.rotation.z = 0.4;

  const group = new Group();
  group.add(camera);

  const scene = new Scene();
  scene.add(group);

  const renderer = new CSS3DRenderer({ element: root.value });

  const sphere13d = new CSS3DObject(sphere1.value);
  sphere13d.position.setX(props.sphereOffset);
  scene.add(sphere13d);
  const sphere23d = new CSS3DObject(sphere2.value);
  sphere23d.position.setX(-1 * props.sphereOffset);
  scene.add(sphere23d);

  renderer.render(scene, camera);

  setInterval(() => {
    group.rotation.y += 0.1;
    camera.rotation.z += 0.01;
    // We need to sync rotation to fake spheres not being flat
    sphere13d.rotation.y = sphere23d.rotation.y = group.rotation.y;
    renderer.render(scene, camera);
  }, 100);

  return { renderer };
}

onMounted(() => {
  const context = init();
  renderer.value = context.renderer;
});
</script>

<style scoped>
* {
  @apply !pointer-events-none;
}
</style>
